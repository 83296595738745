<template>
  <t-modal
    :name="id"
    :variant="`confirmation`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="!isLoading"
    :clickToClose="!isLoading"
    ref="popup"
  >
    <section class="flex justify-center items-center mt-8 mb-2 text-gray-500">
      <i class="text-4xl" :class="icon"></i>
    </section>

    <section class="px-4 text-2xl font-extrabold text-center">
      {{ title }}
    </section>

    <section class="px-4 font-bold text-center">
      {{ subtitle }}
    </section>

    <section class="px-4 my-6 text-center" v-if="criticalityLevel === 'medium'">
      {{ criticalityTitle }}
      <AppInput
        class="text-center"
        type="password"
        v-model="criticalityModels.medium"
      />
    </section>

    <section class="flex items-center py-4 px-4 mt-6 space-x-5 bg-gray-100">
      <anchor-button
        :variant="cancelButtonVariant"
        :class="`w-1/2`"
        @click="executeAction('cancel')"
      >
        {{ cancelButtonText }}
      </anchor-button>

      <anchor-button
        :variant="confirmButtonVariant"
        :class="`w-1/2`"
        :isLoading="isLoading"
        @click="executeAction('confrim')"
      >
        {{ confirmButtonText }}
      </anchor-button>
    </section>
  </t-modal>
</template>

<script>
export default {
  name: 'ConfirmFirst',
  components: {
    AnchorButton: () => import('@/components/form/AnchorButton.vue'),
    // TextInput: () => import('@/components/form/TextInput.vue'),
  },

  props: {
    id: {
      type: [String, Number],
      requred: true,
    },
    icon: {
      type: [String, Number],
      required: false,
      default: 'fas fa-exclamation-circle',
    },
    title: {
      type: [String, Number],
      required: false,
      default: 'Are you sure?',
    },
    subtitle: {
      type: [String, Number],
      required: false,
      default: 'This action cannot be undone.',
    },
    cancelButtonText: {
      type: [String, Number],
      required: false,
      default: 'Cancel',
    },
    cancelButtonVariant: {
      type: String,
      required: false,
      default: 'primary',
      validaotor(val) {
        return [
          'primary',
          'secondary',
          'success',
          'success-alt',
          'warning',
          'warning-alt',
          'disabled',
        ].includes(val)
      },
    },
    confirmButtonText: {
      type: [String, Number],
      required: false,
      default: 'Continue',
    },
    confirmButtonVariant: {
      type: String,
      required: false,
      default: 'warning-alt',
      validaotor(val) {
        return [
          'primary',
          'secondary',
          'success',
          'success-alt',
          'warning',
          'warning-alt',
          'disabled',
        ].includes(val)
      },
    },
    criticalityLevel: {
      type: [String],
      required: false,
      default: 'zero',
      validaotor(val) {
        return ['zero', 'low', 'medium', 'high'].includes(val)
      },
    },
    criticalityTitle: {
      type: [String],
      required: false,
      default: 'Type "CONFIRM" Please',
    },
    criticalityPassword: {
      type: [String, Number],
      required: false,
      default: 'CONFIRM',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    criticalityModels: {
      zero: '',
      low: '',
      medium: '',
      high: '',
    },
  }),

  methods: {
    executeAction(type) {
      if (type === 'confrim' && this.isLoading === false) {
        if (this.criticalityLevel === 'zero') {
          this.$emit('confirm')
        } else if (this.criticalityLevel === 'medium') {
          if (this.criticalityModels.medium === this.criticalityPassword) {
            this.$emit('confirm')
          } else {
            this.$emit('confirm:failed')
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: 'Confirmation Failed!',
              text: 'Invalid confirmation code',
            })
          }
        }
      }

      if (type === 'cancel' && this.isLoading === false) {
        this.$refs.popup.hide()
        this.$emit('cancel')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
